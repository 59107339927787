<template>
  <div class="loading-tree-view">
    <section class="loading-tree-view__section">
      <div class="loading-tree-view__image-wrapper">
        <img v-if="progress <= 40" src="../assets/peanuts.png">
        <img v-else src="../assets/seedling-48px.png">
      </div>
      <h1 class="loading-tree-view__title">Preparing to plant your first seedling...</h1>
      <div class="loading-tree-view__progress-bar--wrapper">
        <progress class="loading-tree-view__progress-bar" :value="progress" max="100"></progress>
      </div>
      <span v-if="progress <= 40">Loading your experience</span>
      <span v-else>Digging a hole</span>
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  data() {
    return {
      progress: 1
    }
  },
  watch: {
    progress(val) {
      if (val >= 100) {
        this.$router.push({ name: 'Welcome' })
      }
    }
  },
  mounted() {
    const updateProgress = () => {
      if (this.progress >= 100) {
        clearInterval(interval);
      } else {
        this.progress++;
      }
    }
    const interval = setInterval(updateProgress, 30);
  }
})
export default class Register extends Vue {

}
</script>

<style lang="scss">
.loading-tree-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.loading-tree-view__title {
  font-size: 36px;
  margin-bottom: 40px;
}
.loading-tree-view__section {
  margin-top: 120px;
  margin-bottom: 400px;
}
.loading-tree-view__progress-bar--wrapper {
  border-radius: 50px;
  overflow: hidden;
  height: 16px;
  margin-bottom: 24px;
}
.loading-tree-view__progress-bar {
  display: block;
  width: 100%;
  height: 16px;
  border: none;
  background: white;
  color: #64B60E;
}
.loading-tree-view__progress-bar::-webkit-progress-value {
  background: #64B60E;
}
.loading-tree-view__progress-bar::-moz-progress-bar {
  background: white;
}
.loading-tree-view__progress-bar::-webkit-progress-value {
  background: #64B60E;
}
.loading-tree-view__progress-bar::-webkit-progress-bar {
  background: white;
}
.loading-tree-view__image-wrapper {
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  width: 48px;
}
</style>
