
import { Options, Vue } from 'vue-class-component';

@Options({
  data() {
    return {
      progress: 1
    }
  },
  watch: {
    progress(val) {
      if (val >= 100) {
        this.$router.push({ name: 'Welcome' })
      }
    }
  },
  mounted() {
    const updateProgress = () => {
      if (this.progress >= 100) {
        clearInterval(interval);
      } else {
        this.progress++;
      }
    }
    const interval = setInterval(updateProgress, 30);
  }
})
export default class Register extends Vue {

}
